
.Loader {
    border: .1em currentcolor solid;
    position: relative;
    -webkit-animation: Loader-1 5s linear infinite;
            animation: Loader-1 5s linear infinite;
    width: 1em;
    height: 1em;
    margin: auto;
  }
.Loader:after {
width: .2em;
height: .2em;
position: absolute;
content: "";
background-color: currentcolor;
bottom: calc(100% + .2em);
left: -.4em;
-webkit-animation: Loader-2 1s ease-in-out infinite;
        animation: Loader-2 1s ease-in-out infinite;
}

@-webkit-keyframes Loader-1 {
0% {
    box-shadow: inset 0 0 0 0 currentcolor;
}
100% {
    box-shadow: inset 0 -1em 0 0 currentcolor;
}
}

@keyframes Loader-1 {
0% {
    box-shadow: inset 0 0 0 0 currentcolor;
}
100% {
    box-shadow: inset 0 -1em 0 0 currentcolor;
}
}
@-webkit-keyframes Loader-2 {
25% {
    left: calc(100% + .2em);
    bottom: calc(100% + .2em);
}
50% {
    left: calc(100% + .2em);
    bottom: -.4em;
}
75% {
    left: -.4em;
    bottom: -.4em;
}
100% {
    left: -.4em;
    bottom: calc(100% + .2em);
}
}
@keyframes Loader-2 {
25% {
    left: calc(100% + .2em);
    bottom: calc(100% + .2em);
}
50% {
    left: calc(100% + .2em);
    bottom: -.4em;
}
75% {
    left: -.4em;
    bottom: -.4em;
}
100% {
    left: -.4em;
    bottom: calc(100% + .2em);
}
}