
.FinalPricing {
  padding: 60px 0;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}


.FinalPricing small {
  font-size: 1rem;
  text-transform: initial;
}
