
.ColumnHeader {
    padding: 60px 0;
    text-align: center;
    font-size: 2em;
    text-transform: uppercase;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}

.ColumnHeader small {
    font-size: 1rem;
    text-transform: initial;
}
