.App {
  text-align: center;
}

.loaderSubtitle {
  color: #333;
}

.loaderTitle {
  color: #333;
}
