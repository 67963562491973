.Overlay {
    position: relative;
}

.panel {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    padding: 50px;
    text-align: center;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2);
    top: -50%;
    transition: top 500ms ease;
}

.Overlay.active .panel {
    top: 50%;
}

