

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.container > * {
  padding: 1em;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 300px;
}

.inner {
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  overflow: hidden;
}

.padded {
  padding-left: 10px;
  padding-right: 10px;
}

.scrollPane {
  height: 500px;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.Option {
  padding: 5px 15px;
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 1;

}

.Feature {
  padding: 15px 15px;
  flex-basis: 300px;
  flex-shrink: 1;
  flex-grow: 1;
}

.scrollPane .Option {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.scrollPane .Feature {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.scrollPane {
  margin-left: -1px;
}

.button {
  margin-bottom: 30px;
  color: #ffffff!important;
  border-width: 0px!important;
  border-radius: 100px;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600!important;
  text-transform: uppercase!important;
  background-color: #fb5353;
  padding-top: 14px!important;
  padding-right: 58px!important;
  padding-bottom: 14px!important;
  padding-left: 58px!important;
  display: inline-block;
  box-shadow: 0px 30px 40px -16px rgba(229,66,25,0.24);
  cursor: pointer;
}

/* 
.scrollPaneShadow {
  overflow: hidden;
  position: relative;
}

.scrollPaneShadow::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  box-shadow: 0 -5px 20px 7px rgba(0, 0, 0, 0.2);
  height: 1px;
}

.scrollPaneShadow::before {
  content: "";
  opacity: 0;
  position: absolute;
  display: block;
  bottom: 0px;
  height: 50px;
  width: 60px;
  left: calc(50% - 30px);
  pointer-events: none;
  z-index: 20;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNjgzIDgwOGwtNzQyIDc0MXEtMTkgMTktNDUgMTl0LTQ1LTE5bC03NDItNzQxcS0xOS0xOS0xOS00NS41dDE5LTQ1LjVsMTY2LTE2NXExOS0xOSA0NS0xOXQ0NSAxOWw1MzEgNTMxIDUzMS01MzFxMTktMTkgNDUtMTl0NDUgMTlsMTY2IDE2NXExOSAxOSAxOSA0NS41dC0xOSA0NS41eiIgZmlsbD0iI2U4NGQ0OSIvPjwvc3ZnPg0K");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: opacity 400ms ease;
}

.scrollPaneShadow[data-scroll='0']::before {
  opacity: 1;
}

.scrollPaneShadow[data-scroll-amount='0']::before {
  opacity: 0;
} */
